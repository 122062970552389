import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../services/message.service'; // Import the service
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-get-report-by-category',
  templateUrl: './get-report-by-category.component.html',
  styleUrl: './get-report-by-category.component.css'
})
export class GetReportByCategoryComponent implements OnInit {
  category: string = '';
  startDate: string = '';
  endDate: string = '';
  errorMessage: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private messageService: MessageService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  downloadReport = async (): Promise<void> => {
    const currentDate = new Date();
    let endDate = this.endDate ? new Date(this.endDate) : null;
    const practiceId = localStorage.getItem('PRACTICE_ID');

    if (endDate && endDate > currentDate) {
      this.toastr.error('End date is in the future!');
      return;
    }
  
    if (!endDate) {
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      this.endDate = `${year}-${month}-${day}`;
    }
  
    this.spinnerService.show();
  
    // Call the service
    this.messageService.getReportByCategory(
      this.category.trim() || null,
      this.startDate.trim() || null,
      this.endDate.trim() || null,
      practiceId
    ).subscribe(
      (data) => {
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `report_by_category.csv`;
        link.click();
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.toastr.error('Something went wrong. Please try again.');
      }
    );
  };
  
}