<div class="modal-header">
    <h4 class="modal-title">Download Report by Category</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="activeModal.dismiss()"
    ></button>
  </div>
  
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <label for="category" class="form-label">Category</label>
        <input
          type="text"
          id="category"
          class="form-control"
          [(ngModel)]="category"
          name="category"
          placeholder="Enter category name"
          required
        />
      </div>
  
      <div class="mb-3">
        <label for="startDate" class="form-label">Start Date</label>
        <input
          type="date"
          id="startDate"
          class="form-control"
          [(ngModel)]="startDate"
          name="startDate"
          required
        />
      </div>
  
      <div class="mb-3">
        <label for="endDate" class="form-label">End Date</label>
        <input
          type="date"
          id="endDate"
          class="form-control"
          [(ngModel)]="endDate"
          name="endDate"
        />
      </div>
    </form>
  </div>
  
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.dismiss()"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="downloadReport()"
    >
      Download Report
    </button>
  </div>
  